@import '@/styles/mixins.scss';

.section {
    width: 100%;
    position: relative;

    &.bordered {
        border-color: var(--colors-primary-light-80);
        border-width: 1px 0;
        border-style: solid;
    }
}

.wrap {
    max-width: 868px;
    margin: 0 auto;
    padding: 70px;
    text-align: center;
    display: grid;
    gap: 16px;
}

@media screen and (max-width: 835px) {
    .wrap {
        padding: 70px 20px;
    }
}
